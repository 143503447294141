@keyframes moveColor {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

.animated-text {
	display: inline-block;
	background-image: linear-gradient(to right, #fff, rgb(33, 103, 215));
	background-size: 200% 200%;
	color: transparent;
	background-clip: text;
	animation: moveColor 3s linear infinite;
}
